.desc_input {
  border: 1px solid #a2a7b5;
}

.desc_input {
  padding: 2px;
  width: 79% !important;
}

/* ---------Modelcss.css----------- */
.fullscreen_paragraph {
  font-size: 14px;
  line-height: 24px;
  font-family: var(--fontfamily2);
  letter-spacing: 0.14px;
  text-align: left;
  color: var(--labelcolor);
}

.verified_uploadeval {
  font-size: var(--fontSizeFourteen) !important;
  margin-top: 20px;
  padding-left: 5px;
  color: var(--bluebrand) !important;
}

.nn_upload_file {
  color: #041134;
  margin-top: 16px;
  font-size: 16px;
  font-family: var(--fontfamily2);
}

/* ------------Popup.css------------- */
.Note_Popup {
  background: var(--alertColorInfo);
  border: 1px solid var(--colorBlue);
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  font-family: var(--fontfamily2);
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: var(--colorBlue);
  padding: 11px 14px;
}

/* ------CenterSupervisor.css----------- */

/* -------CreateCourseBatch.css---------- */
.BathDetDS {
  margin-top: 20px;
  margin-bottom: 100px;
  -webkit-user-select: none;
}

.BathDetDS .StepOne .box {
  /* width: 1280px; */
  height: fit-content;
  background: var(--FieldTitleBoxColor);
  border: 1px solid var(--fieldTitleBorder);
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 30px !important;
  margin-left: 0px;
  margin-right: 0px;
}

.BathDetDS .StepOne .box {
  margin-top: 30px;
}

.BathDetDS .StepOne .box .StepTitle {
  width: fit-content;
  height: 21px;
  /* UI Properties */
  text-align: var(--fieldStepTitlecolorAlign);
  font: normal normal var(--fieldStepTitleWeight) var(--fieldStepTitleSize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--fieldStepTitlecolor);
  /* position: absolute; */
  background: white;
  margin-top: -12px;
  padding: 0 12px;
  margin-bottom: 0px;
}

.BathDetDS .StepOne .box .forms {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}

.BathDetDS .StepOne .box .forms label {
  height: 19px;
  /* UI Properties */
  text-align: var(--labelalign);
  font: normal normal var(--labelweight) var(--labelfont) var(--fontfamily2) !important;
  letter-spacing: 0.28px;
  color: var(--labelcolor);
  opacity: 1;
  margin-top: 17px;
  margin-left: 20px;
}

.BathDetDS .StepOne .box .forms .Inputs:focus-visible {
  background: var(--focusInputBackgroundColor);
  /* border: 2px solid var(--focusInputBordercolor); */
  border: 1px solid var(--bluebrand) !important;
  border-radius: 6px;
  opacity: 1;
  padding: var(--focusInputpadding);
  outline: none;
  margin-top: 10px;
  color: var(--focusInputcolor);
  font-family: var(--fontfamily2);
}

.BathDetDS .StepOne .box .forms .Inputs {
  height: 36px;
  /* UI Properties */
  background: var(--InputBGcolor);
  border: 1px solid var(--InputBorderColor);
  border-radius: 6px;
  opacity: 1;
  margin-left: 20px;
  padding: var(--Inputpadding);
  line-height: var(--LineHeight);
  margin-top: 10px;
  font-family: var(--fontfamily2);
}

.BathDetDS .StepOne .box .forms label,
.BathDetDS .StepOne .box .forms .Inputs {
  margin-left: 0px !important;
}

.BathDetDS .StepOne {
  padding: 0;
}

.BathDetDS .form-check-label,
.BathDetDS .form-label {
  font-size: 14px;
  font-family: var(--fontfamily2);
  opacity: 1;
  color: var(--colorBlue);
  vertical-align: sub;
  margin-left: 10px;
}

.BathDetDS .ReviewLabel,
.BathDetDS .ReviewLabel_sub {
  text-align: left;
  font: normal normal var(--boldtextWeight) var(--reviewlabelsize) var(--fontfamily2);
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  margin-left: 20px;
  word-wrap: break-word;
  margin-bottom: 6px;
}

.BathDetDS .ReviewLabel {
  margin-left: 0px !important;
  font-size: 15px !important;
}

.BathDetDS .BorderQuery {
  width: 97%;
  height: 2px;
  background: var(--colorRed);
  margin: 20px;
}

.BathDetDS .BorderQuery {
  width: 89% !important;
}

.BathDetDS .titleQuery {
  text-align: left;
  font-size: var(--fontSizeFourteen);
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.28px;
  color: var(--colorBlue);
  opacity: 0.5;
  margin-left: 10px !important;
}

.attachmentQuery {
  color: var(--colorRed);
  opacity: 1 !important;
  font-size: 10px;
}

#assign_to_id {
  margin-left: 10px !important;
}

.BathDetDS .iconQuery {
  color: var(--colorRed);
  opacity: 1;
  font-size: var(--fontsizeTwentyOne);
  margin-left: 10px;
}

.BathDetDS .explain_ques {
  text-align: left;
  font-size: 14px;
  font-family: var(--fontfamily2);
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.32px;
  color: var(--colorBlue);
  opacity: 1;
  padding: 10px;
}

/* --------CustomDownloadButton.css-------- */

/* --------AdmissionForm.css-------- */
.download_icon {
  color: var(--colorRed);
  cursor: pointer;
}

.verified_upload {
  font-size: var(--fontSizeFourteen) !important;
  font-family: "Material Icons";
  position: relative;
  top: 7px !important;
  left: 5px;
  color: var(--bluebrand) !important;
}

/* Mobile Response-------- */
/* Media query 400px */
@media screen and (max-width: 576px) {
  .BathDetDS .explain_ques {
    text-align: left;
    font-size: 14px;
    font-family: var(--fontfamily2);
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: var(--colorBlue);
    opacity: 1;
    padding: 10px;
  }

  .desc_input {
    width: 40% !important;
  }
}