.download-csv-link:hover,
.centreSupListBtn.primaryButton a:hover {
  color: #fff !important;
}

.primaryButton {
  padding: 8px 12px;
  border-radius: 3px;
  background-color: #c0272d;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  min-width: 130px;
  height: 36px;
  white-space: nowrap;
  text-align: center;
  font-weight: normal;
}



.primaryButton .material-icons {
  font-size: 22px !important;
}

.primaryButton:hover {
  background-color: var(--bluebrand);
  color: #fff !important;
}

.primaryButton .material-iconsRightAndLeft {
  margin-right: 10px;
}

@media (max-width: 576px) {
  .primaryButton {
    border-radius: 8px;
    cursor: pointer;
    font-size: var(--fontsizetwevele);
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 10px 4px 8px !important;
    font-weight: normal;

  }

  .primaryButton .material-icons {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .primaryButton {
    padding: 8px;
    border-radius: 8px;
    background-color: #c0272d;
    text-decoration: none;
    color: #fff;
    font-size: var(--fontsizetwevele);
    min-width: 130px;
    font-weight: normal;
  }

}