.list_active{
    background-color: #C1393D;
    border: 2px solid #C1393D;
    padding: 1px 13px;
    border-radius: 10px;
    margin-right: 6px;
}
/* Media query for small screens like iPads in portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    .list_active {
        padding: 2px 20px;
    }
}
/* Media query for larger screens */
@media (min-width: 1025px) {
    /* Large screens such as desktops */
    .list_active {
        padding: 2px 20px;
    }
}
.list_nonactive{
    background-color: #fff;
    border: 1px solid #C1393D;
    padding: 1px 13px;
    border-radius: 10px;
    margin-right: 6px;
}  
@media (min-width: 768px) and (max-width: 1024px) {
    .list_nonactive {
        padding: 2px 20px;
    }
}
@media (min-width: 1025px) {
    .list_nonactive {
        padding: 2px 20px;
    }
}
.btn {
    background-color: #C1393D !important;
    border: solid 2px #C1393D !important;
    border-radius: 25px;
    padding: 2px 7px;
    cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .btn .btn_disabled{
        padding: 7px 12px;
    }
}
@media (min-width: 1025px) {
    .btn .btn_disabled{
        padding: 7px 12px;
    }
}
.btn_next{
    border: solid 2px #C1393D;
    border-radius: 25px;
    padding: 7px 12px;
}
.fa-solid{
    color: #C1393D;
}
.Slide_button_active{
    list-style: none;
}
.arrow_btn_prev{
    margin-right: 20px;
}
.btn_disabled {
    border: solid 2px #C1393D !important;
    border-radius: 25px;
    padding: 2px 7px;
    opacity: 0.5;
}
.icon {
    font-size: 16px; /* Adjust size as needed */
}
.icon_disabled {
    color: #fff;
}
.icon_enabled {
    color: #fff;
}
 