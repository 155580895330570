.courseTitle {
  font-size: 16px; /* Default font size */
  color: #C1393D;
  font-weight: 600;
}
/* Media query for small screens like iPads in portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .courseTitle {
      font-size: 24px; 
  }
}
/* Media query for larger screens */
@media (min-width: 1025px) {
  /* Large screens such as desktops */
  .courseTitle {
      font-size: 40px; 
  }
}
.subtitle{
  text-align: center;
  font-size: 14px;
  color: #C1393D;
  opacity: 0.6;
  line-height: 22px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .subtitle {
      font-size: 22px; 
      line-height: 28px;
  }
}
/* Media query for larger screens */
@media (min-width: 1025px) {
  /* Large screens such as desktops */
  .subtitle {
      font-size: 26px; 
      line-height: 35px;
  }
}
.tabs {
  border-bottom: 2px solid #c1393d;
}
.tab-button {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  color: #c4c6cd;
  font-size: 12px;
  font-weight: 700;
}
.tab-button.active {
  border-bottom: 2px solid #c1393d;
  font-weight: bold;
  color: #c1393d;
}
.categoryknow {
  color: #c1393d;
  font-size: 12px;
  font-weight: 700;
  margin-right: 15px;
  text-decoration: none;
}
.IconImg {
  color: #c1393d;
  display: flex;
  align-items: flex-start;
}
.micons {
  margin-top: 10px;
  margin-right: 20px;
}
.confirm_label {
  font-size: 14px;
  color: #041134;
  opacity: 0.5;
  font-weight: 800;
}
.confirm_value {
  color: #041134;
  font-size: 16px;
  font-weight: 800;
}
.confirm_value1 {
  color: #041134;
  font-size: 14px;
  font-weight: 800;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .micons {
    font-size: 18px;
  }
  .confirm_label, p, .select{
    font-size: 12px;
  }
  
  .confirm_value {
    font-size: 13px;
  }
}
.bottomline {
  border-top: 1px solid #c1393d;
  opacity: 0.25;
}
.custom_width {
  width: 100%; /* Default to full width on smaller devices */
}
@media (min-width: 992px) {
  /* lg breakpoint starts at 992px */
  .custom_width {
    width: 25%; /* 25% width on large devices and up */
  }
}
.course_underline_img {
  position: absolute;
  width: 8%;
  top: 20px;
}
@media (min-width: 992px) {
  /* lg breakpoint starts at 992px */
  .course_underline_img {
    position: absolute;
    width: 6%;
    top: 43px;
  }
}
/* Media query for small screens like iPads in portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .course_underline_img {
    position: absolute;
    width: 6%;
    top: 28px;
  }
  .micons {
    font-size: 20px; 
  }
  .confirm_label {
    font-size: 12px; 
  }
  .confirm_value {
    font-size: 13px;
  }
}
/* Media query for larger screens */
@media (min-width: 1025px) {
  /* Large screens such as desktops */
  .course_underline_img {
    position: absolute;
    width: 6%;
    top: 43px;
  }
}
.primaryButton {
  padding: 10px 14px;
  border-radius: 8px;
  background-color: #c0272d;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  min-width: 130px;
}
/* select option dropdown  */
.dropdownarrow_img {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 8 4.94'%3e%3cpath id='ic_keyboard_arrow_down_24px' d='M6.94,7.84,10,10.893,13.06,7.84l.94.94-4,4-4-4Z' transform='translate(-6 -7.84)' fill='%232378e9'/%3e%3c/svg%3e")
    no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: center !important;
  padding-right: 24px;
  width: 100%;
}
.dropdownarrow_img option:checked {
  background-color: #2378e9;
  color: white;
}
.dropdownarrow_img option:hover {
  background-color: #2378e9 !important;
  color: white !important;
}
.upcoming_batch_hr {
  border: 0; /* Remove default border */
  height: 1px; /* Set height to 1px for the line */
  background-color: #c1393d; /* Set the line color to red */
  margin: 1rem 0; /* Adjust vertical spacing if needed */
}
.course_img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}
.coursebatch_container{
  border: solid 3px #c1393d;
  border-radius: 8px;
}
.coursebatch_container fieldset {
  border: 2px solid #041134;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
}
.coursebatch_container legend {
  float: none;
  font-size: 14px;
  font-weight: bold;
  color: #c1393d;
  padding: 0 5px;
  width: auto;
}
.separator_pattern{
  width: 50%;
  height: auto;
  object-fit: cover;
}
.SaveButton {
  background: #C0272D;
  border-radius: 6px;
  padding: 10px 30px;
  color: #fff;
  font-size: 14px;
  border: none;
  display: inline-block; 
  /* justify-content: center; */
  /* align-items: center;  */
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background 0.4s ease; /* Transition for background */
}

.SaveButton:hover {
  background: var(--bluebrand);
  color: white !important;
}

.buttonText {
  display: inline-block;
  position: relative;
  left: 0;
  transition: left 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.arrowIcon {
  position: absolute;
  right: 50%;
  opacity: 0;
  transition: right 0.6s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.4s ease-in;
}

.SaveButton:hover .buttonText {
  /* margin-right: auto; 
  transform: translateX(0); */
  left: -40px;
}

.SaveButton:hover .arrowIcon {
  opacity: 1; 
  right: 10px;
  /* font-size: 20px; */
}
.contentHeight{
  max-height: 550px;
  overflow-y: auto;
}
.cursorPtn{
  cursor: pointer;
}
.disabledOption {
  font-size: 14px;
  background-color: #dcdcdc;
  font-weight: 600;
}