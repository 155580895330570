/* Base font size for all devices */
.squadtitle {
    font-size: 16px; /* Default font size */
    color: #C1393D;
    font-weight: 600;
}

/* Media query for small screens like iPads in portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    .squadtitle {
        font-size: 24px; 
    }
}
/* Media query for larger screens */
@media (min-width: 1025px) {
    /* Large screens such as desktops */
    .squadtitle {
        font-size: 40px; 
    }
}
.subtitle{
    text-align: center;
    font-size: 14px;
    color: #C1393D;
    opacity: 0.6;
    line-height: 22px;
}
/* mobile device */
@media (max-width: 576px) {
    .SquadText{
        font-size: 14px;
    }
    .SquadHeader{
        font-size: 12px;
    }
}
/* Media query for small screens like iPads in portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    .subtitle {
        font-size: 22px; 
        line-height: 28px;
    }
}
/* Media query for larger screens */
@media (min-width: 1025px) {
    /* Large screens such as desktops */
    .subtitle {
        font-size: 26px; 
        line-height: 35px;

    }
}
.marklist_container{
    border : solid 3px #C1393D;
    border-radius: 8px;
}
.SquadContainer{
    padding: 10px;
}
.SquadContentBox{
    border: 1px solid #707070;
    border-radius: 6px;
}
.SquadRank{
    font-size: 64px;
    color: #C1393D;
    font-style: italic;
    margin: 0;
}
.SquadImg{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}
.SquadName{
    font-size: 20px;
    font-weight: 600;
    margin: 8px 0;
    color: #C1393D;
}
.SquadHeader{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #041134;
    opacity: 0.5
}
.SquadText{
    font-size: 16px;
    color: #041134;
    font-weight: bold;
    margin: 0;
    margin-bottom: 8px;
}
@media (max-width: 600px) {
    .SquadHeader{
      font-size: 12px;
    }
    .SquadText{
      font-size: 13px;
    }
    .SquadRank{
      font-size: 50px;
    }
    .SquadName{
      font-size: 16px;
    }
  }
.reset {
    all: revert;
    color: #c1393d;
    padding: 0 5px;
}
.course_underline_img{
    position: absolute;
    width: 8%;
    top: 20px;
  }
  @media (min-width: 992px) {
    /* lg breakpoint starts at 992px */
    .course_underline_img {
      position: absolute;
      width: 6%;
      top: 43px;
    }
  }
  /* Media query for small screens like iPads in portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {
    .course_underline_img {
      position: absolute;
      width: 6%;
      top: 28px;
    }
  }
  /* Media query for larger screens */
  @media (min-width: 1025px) {
    /* Large screens such as desktops */
    .course_underline_img {
      position: absolute;
      width: 6%;
      top: 43px;
    }
  }