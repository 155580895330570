@import url("../src/Themes/themes.css");
 
 
 
.table-responsive::-webkit-scrollbar-thumb {
  background: var(--bluebrand) !important;
  border-radius: 50px !important;
  -webkit-appearance: none !important;
  height: 1px !important;
  /* width: 50% !important; */
}
 
.viewTestInstruction li::marker {
  color: #c1393d;
  font-size: 20px;
}
 
 
 
.table-responsive::-webkit-scrollbar-track {
  background-color: transparent !important;
  /* Set the scrollbar track to be transparent */
}
 
table {
  width: 100%;
  box-sizing: border-box;
  /* Include padding and border in the width calculation */
}
 
/* Customize the scrollbar track (background) */
::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width as needed */
}
 
/* Customize the scrollbar thumb (handle) */
::-webkit-scrollbar-thumb {
  background: var(--bluebrand);
  border-radius: 50px;
  border-width: 2px;
}
 
/* Customize the scrollbar thumb when hovering */
::-webkit-scrollbar-thumb:hover {
  background: var(--bluebrand);
}
 
/* Customize the scrollbar track when scrolling */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Change the color of the track while scrolling */
}
 
::-webkit-scrollbar-corner {
  background: #f1f1f1;
  /* Change the color of the corner */
}
 
body {
  scrollbar-width: 2px;
  scrollbar-color: var(--bluebrand) #f1f1f1;
  font-family: var(--fontfamily2);
  color: var(--colorBlue);
}
 
input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-password-saved {
  display: none;
}
 
/* -------Container - Header (Row) -------- */
/* -------View Student and Upload Answer sheet (Admit - Test Model descriptive) */
 
.global_container fieldset {
  border: 1px solid #041134;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
}
 
.global_container legend {
  float: none;
  font-size: 14px;
  font-weight: bold;
  color: #c1393d;
  padding: 0 5px;
  /* width: auto; */
}
 
.global_container .confirm_label {
  font-size: 14px;
  color: #041134;
  opacity: 0.5;
  font-weight: 800;
}
 
.global_container .confirm_value {
  color: #041134;
  font-size: 14px;
  font-weight: 800;
  overflow-wrap: break-word;
}
 
.global_container .filterRadioInput .confirm_value,
.global_container .filterCheckboxInput .confirm_value {
  color: #041134;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 0;
  margin-left: 5px;
}
 
/* // chipdata added by lalitha */
 
.global_container .clearAll_list {
  padding: 4px 8px;
}
 
.global_container .clearAll_listItem {
  font-size: var(--fontSizeFourteen);
  font-weight: var(--boldtextWeight);
  color: var(--colorRed);
  text-decoration: none;
}
 
/* // chipdata  and filter active icon */
.global_container .paperil_global ul {
  justify-content: flex-start;
  box-shadow: none;
}
 
/* ------- */
/* -------Header of container --(Below Fieldset row comes-- */
.ViewContainerListBtn {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  /* flex-grow: 1; */
}
 
.BacktoPreviousButtonIndex {
  color: #c0272d !important;
  text-decoration: none;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  padding: 6px 14px;
}
 
.HeadlistButton {
  margin-top: 10px;
}
 
.HeadCenterListBtn {
  flex-direction: row;
  text-align: center;
}
 
.input-font-size {
  font-size: 14px;
}
 
#tableListHeader th {
  background-color: #041134;
  color: #fff;
  font-size: 14px;
  position: relative;
}
 
#tableListHeader thead {
  border: none;
  text-wrap: nowrap;
  white-space: nowrap;
  /* Safari browser */
}
 
#tableListHeader td {
  white-space: nowrap;
  font-size: 14px;
  padding: 15px 0 16px 10px;
  word-break: break-all;
}
 
#tableListHeader tbody tr:hover td  {
  background-color: #2378e9;
  color: white !important;
}
#tableListHeader tbody tr:hover td .colorSkyBlue {
  background-color: #2378e9;
  color: white !important;
}
 
 
#tableListHeader tbody tr:hover .material-icons {
  color: white !important;
}
 
 
 
.input-font-size-sm {
  font-size: 15px;
}
 
.primary-color {
  color: #c0272d;
}
 
/* Title */
.BathHeader {
  padding: 15px !important;
}
 
.BathHeader .TitleHeader {
  font: var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  margin-bottom: 0;
  padding: 12px 0px;
}
 
.ModalHeader {
  font: var(--titlefontWeight) var(--titlefontSize) var(--fontfamily2);
  margin-bottom: 0;
  padding: 12px 0px;
}
 
.List-Button li:last-child {
  margin-right: 0;
  /* Removes margin from the last child */
}
 
/* .ListBtn {
  display: flex;
  justify-content: end;
} */
 
/* table filter button, */
.filterBoxs_testlist {
  min-width: 280px;
  background: #ffffff;
  box-shadow: 0px 3px 6px var(--blackBoxShadow);
  border-radius: 10px;
  position: absolute;
  /* left: 5px; */
  right: 0px;
  top: 35px;
  padding: 10px;
  z-index: 100;
}
 
.filterStatus_list {
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0px;
  padding-bottom: 10px;
}
 
.ulFilterListData {
  padding-left: 0px;
  margin: 0;
}
 
/* .borderWidth {
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin: 0;
} */
.borderWidth {
  position: relative;
  padding-top: 10px;
  margin: 0;
  border-top: 1px solid rgba(192, 39, 45, 0.5);
}
 
.borderWidth .List-Button {
  justify-content: space-between;
  padding: 0 0 5px 0;
}
 
.tableMinHeight {
  min-height: 400px;
}
 
.filterListData {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
 
.ListCheckbox {
  height: 20px;
  width: 20px;
  border-radius: 8px;
  border: 1px solid #041134;
  cursor: pointer;
}
 
.bg-pink {
  background-color: var(--colorPink);
}
 
.bg-orange {
  background-color: #E4A11B;
}
 
.filterChipText {
  padding: 5px;
  color: var(--colorWhite) !important;
  font-size: var(--fontsizetwevele) !important;
  margin-left: 10px;
  border-radius: 2px;
  cursor: pointer;
}
 
.bg-green {
  background-color: var(--colorGreen);
}
 
.bg-grey {
  background-color: var(--colorGrey);
}
 
 
.CircleRoundedIcon {
 
  color: var(--colorRed);
  height: 10px !important;
width: 10px !important;
position: absolute;
top: -2px;
right: -3px;
}
 
 
.dateFilterTable {
  margin: 10px 0;
}
 
/* .paginatonDiv, */
.List-Button {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: end;
}
 
.applyFilterBtn {
  padding: 5px 2px;
  border-radius: 3px;
  background-color: #c0272d;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  min-width: 108px;
  height: 30px;
  white-space: nowrap;
  text-align: center;
  /* max-height: 36px; */
}
 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
 
.FilterListButton {
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
 
/* modal body height */
.ModalBodyHeight {
  min-height: 200px;
}
 
.ListBtnItems {
  display: flex;
  margin-top: 4px;
  margin-right: 10px;
}
 
.form-check-input {
  width: 18px;
  height: 18px;
  /* margin-top: 0px; */
  opacity: 0.7;
  border: 1px solid #041134;
}
 
.form-check-input:checked {
  background-color: var(--bluebrand);
  border-color: var(--bluebrand);
}
 
.form-check-input:disabled {
  opacity: 1 !important;
}
 
#date-pick-icon {
  top: 7px;
  right: 7px;
}
 
.listButton {
  margin-top: 10px;
}
 
.secondaryButton {
  color: #c0272d !important;
  text-decoration: none;
  margin-right: 10px;
  font-size: var(--fontSizeFourteen);
  cursor: pointer;
  padding: 6px 14px;
}
 
.table>thead {
  text-wrap: nowrap;
}
 
.material-icons {
  cursor: pointer;
}
 
.border_head_color {
  border: 1px solid #a2a7b5;
}
 
.font_size_sixteen {
  font-size: 16px !important;
}
 
/* time Read */
.timeRead {
  padding: 5px;
  text-align: center;
  color: #c1393d;
  font-size: 14px;
  font-weight: bold;
}
 
.Timing_loading_Seconds {
  /* height: 40px; */
  box-shadow: 3px 3px 10px #00000029;
  border: 2px solid var(--colorRed);
  border-radius: 6px;
  /* margin-top: 20px; */
  text-align: center;
  width: 130px;
  margin-top: 5px;
}
 
.InputsSelectFile {
  /* border: 1px solid var(--InputBorderColor); */
  border: solid 1px #b3b3b3;
  border-radius: 6px;
  margin-top: 10px;
  padding: var(--InputFilepadding);
  width: 100%;
}
 
.form-control {
  border: solid 1px #b3b3b3;
}
 
.nn_upload_fileevaluator {
  font-size: 14px !important;
  margin-top: 15px;
}
 
/* -----Mobile responsive */
 
/* -----Mobile responsive */
 
@media (max-width: 576px) {
  .BathHeader .TitleHeader {
    margin-top: 10px;
    padding: 10px 0px;
  }
 
  .ListBtn {
    flex-direction: column;
    text-align: center;
    /* padding: 0; */
  }
 
  .filterBoxs_testlist {
    left: 0px;
    right: 0px;
  }
 
  .List-Button {
    display: block;
    padding: 0 !important;
  }
 
  .listButton {
    margin: 0px 0px 10px 0px;
  }
 
  .nn_upload_fileevaluator {
    font-size: 12px !important;
    margin-top: 5px;
  }
 
 
 
  .secondaryButton {
    font-size: var(--fontsizetwevele);
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 0px !important;
    font-weight: normal;
  }
 
  .ListBtnItems {
    flex-direction: column;
    margin: 0;
  }
 
  #tableListHeader th {
    font-size: 12px;
  }
 
  .global_container legend {
    font-size: 12px;
  }
 
  #tableListHeader td {
    font-size: 12px;
  }
 
  #tableListHeader .material-icons {
    font-size: 16px;
  }
}
 
@media (max-width: 768px) {
 
  .global_container .confirm_value,
  .global_container .confirm_label,
  .BacktoPreviousButton,
  #tableListHeader th .assignBtn_head,
  .input-font-size,
  .input-font-size-sm,
  .font_size_sixteen {
    font-size: 12px !important;
  }
 
  .secondaryButton {
    padding: 0;
    display: flex;
    font-weight: normal;
    align-items: center;
    justify-content: center;
  }
 
  .global_container .confirmValue,
  .global_container .confirmLabel,
  .BacktoPreviousButton,
  #tableListHeader th .assignBtn_head,
  .input-font-size,
  .input-font-size-sm {
    font-size: 12px;
  }
 
  .HeadContainer .TitleHeadContainer {
    font-size: 12px;
    font-weight: var(--titlefontWeight);
    color: var(--Titlecolor);
    margin-top: 10px;
    padding: 10px 0px;
  }
 
  .BacktoPreviousButtonIndex {
    text-align: center;
    margin: 0px 0px 10px 0px !important;
    padding: 0px !important;
  }
 
  .ViewContainerListBtn {
    margin: 0;
    list-style-type: none;
 
    padding: 0 !important;
  }
 
  .HeadlistButton {
    margin: 0px 0px 10px 0px;
  }
}
 
/* ------Nav Item -------- */
.course-content-nav {
  border-bottom: 1px solid #c1393d;
}
 
.course-content-nav .nav .nav-item .nav-link {
  font-size: 12px;
  cursor: pointer;
  color: #041134;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 24px;
}
 
.course-content-nav .nav .nav-item .nav-link.active {
  color: #c1393d;
  border-bottom: 1px solid #c1393d;
  font-weight: 800;
}
 
/* //added by lalitha for course management */
 
.bg-yellow {
  background-color: var(--colorYellow);
}
 
.bg-lightred {
  background-color: var(--colorLightRed);
}
 
.filterChipText_withoutBG {
  padding: 5px;
  color: var(--colorBlue) !important;
  font-size: var(--fontSizeFourteen) !important;
  margin-left: 10px;
  border-radius: 2px;
  white-space: initial;
}
 
 
.desc_input_text {
  padding: 6px 8px;
  /* border: 1px solid #a2a7b5; */  
  border: 1px solid hsl(0, 0%, 80%);
}
 
.desc_input_text:focus-visible {
  border: 2px solid #2378e9;
  outline: none;
}
 
.material_icons_addMore {
  margin-right: 5px;
}
 
/* table view icon code */
 
.view_icon_button {
  color: #c0272d;
  border: none;
  background: none;
}
 
.view_icon_button .visibleicon {
  margin-right: 15px;
}
 
.view_icon_button .material-icons {
  font-size: 20px;
}
 
/* added by lalitha  */
 
.error_message {
  color: var(--errortextcolor);
  font-weight: var(--errortextweight);
  font-size: var(--fontSizeFourteen);
}

/* added by lalitha for disabled functionality */
.disabled_btn,
.disabledbtn {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

span.text-regular {
  font-weight: 400;
}

 
/* added by lalitha for disabled functionality */
.disabled_btn,
.disabledbtn {
  /* background: #c0272d !important; */
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
 
span.text-regular {
  font-weight: 400;
}
 
.disabled_Name {
  opacity: 1;
  cursor: not-allowed !important;
}

.disabledbtn:hover {
  background: #c0272d !important;
}

 
.disabledbtn:hover {
  background: #c0272d !important;
}
 
.disabled_btnsss {
  opacity: 0.3;
  cursor: not-allowed !important;
}
 
.LI_FilterInputs {
  padding: var(--Inputpadding);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0411341f;
  border-radius: 6px;
}
 
.LI_filterIocnBoxInput {
  position: relative;
  top: 8px;
  right: 35px;
  color: var(--bluebrand);
  opacity: 1;
  font-size: 22px;
}
 
.global_dot_chat {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: var(--colorRed) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 2px;
}
 
.display_none {
  visibility: hidden;
}
 
@media (max-width: 767px) {
  .error_message {
    white-space: initial;
    font-size: 12px;
  }
 
  .filterChipText_withoutBG {
    font-size: 12px !important;
  }
 
  .ListCheckbox {
    height: 15px;
    width: 15px;
  }
}
 
/* ---------------------------Course Management -------------------------------------- */
#cm_listViewTabs {
  border-bottom: 1px solid #c1393d;
}
 
#cm_listViewTabs li a {
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  color: #041134;
  opacity: 0.4;
  outline: none;
}
 
#cm_listViewTabs .nav-link.active {
  background-color: transparent;
  border: none;
  opacity: 1;
  border-bottom: 1px solid #c1393d;
  color: #c1393d !important;
}
 
.cm_nav-fill .nav-item,
.cm_nav-fill>.nav-link {
  flex: 0 1 auto !important;
  margin: 0px 20px;
  width: fit-content;
}
 
.padding_10_0 {
 
  padding: 10px 0;
}
.cursorPnt{
  cursor: pointer;
}
 
