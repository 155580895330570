.desc_input {
  border: 1px solid #a2a7b5;
}
.desc_input {
  padding: 2px;
 
}
.margin_right {
  margin-right: 20px;
}
.verified_uploadeval {
  font-size: var(--fontSizeFourteen) !important;
  margin-top: 20px;
  padding-left: 5px;
  color: var(--bluebrand) !important;
}
.nn_upload_width {
  width: 100px !important;
}

/* ----------Admission Form------- */
.checkbox_radio:checked {
  width: 18px !important;
  height: 18px !important;
  background: var(--bluebrand) 0% 0% no-repeat padding-box;
  border: 1px solid var(--bluebrand);
  opacity: 1;
  margin-right: 10px;
}
.checkbox_radio {
  width: 18px !important;
  height: 18px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  opacity: 0.9;
  /* vertical-align: -webkit-baseline-middle; */
  /* appearance: none; */
  margin-right: 10px;
  border-radius: 50%;
}
.form_label {
  font-size: 14px;
  font-family: var(--fontfamily2);
  opacity: 1;
  color: var(--colorBlue);
  vertical-align: sub;
}
@media only screen and (max-width: 576px) {
  /* --------AdmissionForm.css------- */
  .checkbox_radio:checked {
    background: var(--bluebrand) 0 0 no-repeat padding-box;
    border: 1px solid var(--bluebrand);
    height: 18px !important;
    margin-right: 6px;
    opacity: 1;
    width: 18px !important;
  }
}
