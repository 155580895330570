.CourseInfoSec .errors {
    font-size: var(--errortextsize);
    color: var(--errortextcolor);
    font-weight: var(--errortextweight);
  }
  .CourseInfoSec .iconsType {
    display: none;
  }
  
  .CourseInfoSec .prev_buttonViewEvaluator:disabled {
    border: #a9a9a9;
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: not-allowed;
    opacity: 0.6;
  }
  .allclear {
    font-family: var(--fontfamily2);
    font-size: var(--fontSizeFourteen);
    font-weight: var(--boldtextWeight);
    text-align: left;
    /* font: normal normal medium 14px/19px var(--fontfamily2); */
    letter-spacing: 0px;
    color: var(--colorRed);
    opacity: 1;
    position: relative;
    top: 3px;
    text-decoration: none;
  }
  .CourseInfoSec .prev_buttonViewEvaluator {
    background-color: #c0272d;
    border: 1px solid var(--colorRed);
    border-radius: 4px;
  }
  
  .CourseInfoSec .prev_buttonViewEvaluator .materialIconsRightAndLeft {
    color: #fff;
    vertical-align: text-bottom;
    font-family: "Material Icons";
  }
  
  .CourseInfoSec .prev_buttonViewEvaluator .materialIconsRightAndLeft {
    color: #fff;
    vertical-align: text-bottom;
    font-family: "Material Icons";
  }

  .CourseInfoSec .obtainedMarks {
    border-radius: 6px;
    border: 1px solid lightgray;
  }
  .CourseInfoSec .active_icons {
    background-color: var(--colorRed) !important;
    border-radius: 2px;
  }
  
  .CourseInfoSec .borderWidth {
    border-top: 1px solid #dee2e6;
    padding-top: 5px;
    margin: 0;
  }
  
  .CourseInfoSec .overlay {
    position: fixed;
    /* or absolute based on your layout */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    /* Semi-transparent */
    z-index: 10;
    /* Above other content */
    pointer-events: none;
    /* Allow clicks through the overlay */
  }
  
  .CourseInfoSec .overlay.active {
    pointer-events: auto;
    /* Block clicks when active */
  }

.cm_ListViewForm .Inputs {
    border: 1px solid #77041134;
    border-radius: 6px;
    height: 36px;
    margin-top: 10px;
    opacity: 1;
    padding: 8px 8px 9px 10px;
    width: 80%;
    /* font-weight: bold; */
    font-size: 14px;
}
.Inputs:focus-visible{
    outline: none;
}
.cm_ListViewForm .input_group_text {
    background: #fff;
    border: none;
    border-bottom: 1px solid #77041134;
    border-right: 1px solid #77041134;
    border-top: 1px solid #77041134;
    border-radius: 6px;
    margin-left: 20px;
    margin-top: 10px;
    height: 36px;
    line-height: 18px;
    padding: 8px 8px 9px 10px;
}
.ellipsis {
    display: inline-block;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #td {
    white-space: nowrap;
    font-size: var(--fontSizeFourteen);
    padding: 15px 0 15px 10px;
    word-break: break-all;
  }
  .BoldTy {
    text-align: left;
    width: max-content;
    display: contents;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #041134;
    opacity: 1;
  }
  
  .BoldTyText {
    text-align: right;
    width: max-content;
    display: contents;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #041134;
    opacity: 0.5;
    padding-left: 5px;
  }

.input_group_text {
    color: #2378e9 !important;
}
@media only screen and (max-width: 576px) {
    .CourseInfoSec .material_icons {
        font-size: 16px;
      }
    .cm_ListViewForm .Inputs {
        font-size: 12px;
    }
    #td {
      white-space: nowrap;
      font-size: var(--fontsizetwevele);
    }
}
