.headerwel {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 576px) {
    #carouselExampleIndicators {
      margin-top: 20px !important;
    }
}
.carousel_indicators {
    display: none !important;
}

/* Announcement */
.icon_circle {
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
  width: 30px; /* Adjust the size of the circle */
  height: 30px; /* Adjust the size of the circle */
  border-radius: 50%; /* This makes it circular */
  background-color: white; /* Red background */
  color: #c0272d; /* White icon */
  font-size: 20px; /* Adjust the size of the icon */
}

.icon_circle svg {
  fill: #c0272d; /* Ensure the icon is white */
  padding: 3px;
  margin: 0px;
}
.announcemt_container{
  height: 290px;
  width: 400px;
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 1;
  background-color: #fff;
  border-radius: 7px;
}
.announcemt_container_user{
  height: 290px;
  width: 400px;
  position: absolute;
  top: 180px;
  right: -2px;
  z-index: 1;
  background-color: #fff;
  border-radius: 7px;
}
.marquee_left_icon_user{
  z-index: 0;
  position: absolute;
  top: 180px;
  right: -2px;
  background: #c0272d;
  color: #fff;
  padding: 7px;
  border-radius: 7px 0 0 7px;
}
.Header_portion{
    background: #c0272d;
    border-radius: 7px 7px 0px 0px;
    text-align: center;
    align-items: center;
    color: #fff;
}
.marquee_left_icon{
  z-index: 0;
  position: absolute;
  top: 50px;
  right: 10px;
  background: #c0272d;
  color: #fff;
  padding: 7px;
  border-radius: 7px 0 0 7px;
}
/* Mobile (up to 767px) */
@media screen and (max-width: 767px) {
  .marquee_left_icon {
    z-index: 0;
    position: absolute;
    top: 25px;
    right: 10px;
    background: #c0272d;
    color: #fff;
    padding: 0px;
    border-radius: 4px 0 0 4px;
}
.announcemt_container {
    font-size: 14px;
    height: 280px;
    width: 220px;
    position: absolute;
    top: 25px;
    right: 10px;
    z-index: 1;
    background-color: #fff;
    border-radius: 7px;
}
}
.decription{
  padding: 0;
  list-style: none;
  margin-bottom: 5px;
  color: #2378e9;
  text-decoration: underline;
  margin-bottom: 12px;
}
.categoryName{
  font-size: 16px ;
  font-weight: 600 ;
  background-color: #c0272d;
  color: #ffffff;
  padding: 5px 10px;
  
}

.marquee_portion {
  border: 2px solid #c0272d;
  border-radius: 0 0 10px 10px;
}

.listImg{
 width: 20px;
 height: auto;
 margin-right: 10px;
 vertical-align: middle;
}

/* marquee ul li a::before{
  content: url('./ic_send_24px.png');
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
} */

/* marquee ul li {
  padding-left: 30px;
  position: relative;
} */

marquee ul li a:hover {
  color: #c0272d !important;
}
